<template>
  <div class="excel-load flex flex-column card-header-divider">
    <div
      v-if="!loadStarted"
      class="excel-load__label display--flex justify-content-center"
    >
      <div class="excel-load__block">
        <div>
          1. Выберите файл с сотрудниками (<span class="excel-load__example"
            ><a :href="url">Скачать пример</a></span
          >)
        </div>
        <custom-file-loader
          :showFileName="true"
          name="excel-loader"
          @loading="loadingAttempt"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
      <div class="excel-load__block">
        <div>2. Выберите группу</div>
        <custom-select-2
          name="excel-group"
          v-model="selectedGroup"
          :options="groupsOptions"
        />
      </div>
      <div class="excel-load__block">
        <div>3. Нажмите на кнопку ниже и ожидайте</div>
        <button @click="load" class="btn btn-accent mr-10">Загрузить</button>
      </div>
    </div>

    <preloader v-if="loadStarted" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomFileLoader from "@/components/Forms/Fields/CustomFileLoader.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
import { useRoute, useRouter } from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";

export default {
  components: { CustomSelect2, CustomFileLoader, Preloader },
  name: "excel-load-row",
  setup() {
    const store = useStore(),
      router = useRouter(),
      route = useRoute(),
      url = `${process.env.VUE_APP_BACK}/storage/user_download_example.xlsx`;
    const hideOptions = ref(true),
      groups = computed(() => store.state.group.groups),
      groupsOptions = computed(() =>
        groups.value
          ? groups.value.map((group) => {
              return { id: group.id, text: group.name };
            })
          : []
      ),
      selectedGroup = ref(null);

    let selectedFile = null;
    let loadStarted = ref(false);

    onBeforeMount(() => {
      if (route.path !== "/group") store.dispatch("group/getGroups");
    });

    const loadingAttempt = (files) => {
      selectedFile = files[0];
    };

    const load = () => {
      if (!selectedGroup.value) {
        notify({
          type: "warning",
          title: "Необходимо выбрать группу",
        });
      } else if (!selectedFile) {
        notify({
          type: "warning",
          title: "Необходимо выбрать файл",
        });
      } else {
        loadStarted = true;
        store
          .dispatch("group/loadGroupFromExcel", {
            file: selectedFile,
            group_id: selectedGroup.value,
          })
          .then(() => {
            router.push("/excel");
          });
      }
    };

    return {
      hideOptions,
      groups,
      groupsOptions,
      selectedGroup,
      selectedFile,
      loadingAttempt,
      load,
      loadStarted,
      url,
    };
  },
};
</script>

<style lang="scss" scoped>
.excel-load {
  &__block {
    width: 100%;
    padding: 15px;
    border: 2px solid #ebebeb;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .btn-accent {
      margin-top: 10px;
    }
  }

  &__block:not(:last-child) {
    border-right: none;
  }

  &__label {
    margin-bottom: 20px;
    font-weight: 400;

    @media (min-width: 974px) {
      padding-left: 10px;
    }
  }

  @media (max-width: 1200px) {
    &__label {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      width: 100%;
    }
    &__block:not(:last-child) {
      border-right: 2px solid #ebebeb;
      border-bottom: none;
    }
  }

  &__row {
    .form-group:first-child {
      min-width: 50%;
    }

    .form-group {
      max-width: 50%;
    }
  }

  // .excel-load__options

  &__options {
  }

  // .excel-load__example

  &__example {
    a {
      color: var(--main-color);
      font-weight: 300;
      font-size: 16px;
      cursor: pointer;
      padding: 6px;

      &:hover {
        color: var(--hover-color);
        border-color: var(--hover-color);
      }
    }
  }

  @media (max-width: 1024px) {
    &__row {
      flex-direction: column;
      align-items: flex-start;

      .form-group,
      .form-group:first-child {
        min-width: 100%;
        margin-bottom: 20px;
      }

      .form-group:not(:first-child) {
        align-items: center;
      }
    }

    .form-group > div {
      padding: 0;
    }

    &__example {
      margin-top: 15px;
    }
  }
}
</style>